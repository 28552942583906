/** SEARCH BOX */
@import "../../../../common/assets/web/ui/MTSelect/css/mt_select";
/** SWITCH BOX */
@import "../../../../common/assets/web/ui/SwitchCheckBox/src/css/switchcheckbox";

.search-bar {
  border: 1px solid rgba(0,0,0,.200);
  border-radius: 5px;
  clear: both;
  margin-top: 15px;
  width: 80%;
  &:after{
    display: block;
    content: "";
    clear: both;
  }
  .input-divider {
    border-left: 1px solid rgba(0,0,0,.200);
  }
  .input-search-wrapper {
    width: 81%;
  }
  .form-control { border: none; }
  .ro-select-text {
    padding: 5px 24px 1px 0;
    border: none;
    &.ro-select-text-empty {
      color: #000;
    }
  }
  .ro-select-caret {
    top: 8px;
  }
}

.reset-box {
  width: auto;
  color: #444;
  margin-top: 180px;
}

.file-thumbnail-wrapper {
  background-color: #ccc;
}

#slider_image img.placeholder {
  width: 70px;
  height: 70px;
}

.modal-dialog .modal-body {
  overflow-y: auto;
  position: relative;
}

.modal-dialog .nav-tabs-custom {
  height: 98%;
}

.nav-tabs,
.nav-pills {
  position: relative;
}


div.indent {
  float: left;
  width: 30px;
}

.field-slideritem-image_path {
  overflow: hidden;
}

.file-thumbnail-wrapper,
.file-button-wrapper {
  float: left;
}

.file-thumbnail-wrapper {
  margin-right: 10px;
}

.square-list {
  background-color: #f9f9f9;
  border: 1px solid #fff;
  font-size: 11px;
  height: 115px;
  line-height: 1.4;
  padding: 10px;
  text-align: center;
}


.rounded-image {
  border-color: rgb(136, 136, 136);
  border-image: none 100% 100% 100% 100%; // 0 0 0 0;
  border-radius: 50%;
  border-style: none;
  border-width: 0;
  box-sizing: border-box;
  color: rgb(136, 136, 136);
  cursor: pointer;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: auto;
  line-height: 21px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  width: 100%;
}


#config-widget-wrapper {
  overflow: hidden;
}

.map-overlay{
  width: 100%;
  height: 300px;
}
