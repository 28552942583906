.lcs_wrap,
.lcs_wrap * {
    user-select: none;
}
.lcs_wrap {
    display: inline-block;
    direction: ltr;
    height: 28px;
    width: 73px;
    vertical-align: middle;
}
.lcs_wrap input {
    display: none;
}
.lcs_switch {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background: #ddd;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease-in-out;
}
.lcs_cursor {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .1);
    z-index: 10;
    transition: all .2s linear;
}
.lcs_label {
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    width: 33px;
    top: 5px;
    overflow: hidden;
    text-align: center;
    opacity: 0;
    text-shadow: 0 0 2px rgba(0,0,0, .1);
    transition: all .2s ease-in-out .1s;
}
.lcs_label.lcs_label_on {
    left: -70px;
    z-index: 6;
}
.lcs_label.lcs_label_off {
    right: -70px;
    z-index: 5;
}
.lcs_switch.lcs_on {
    background: #75b936;
}
.lcs_switch.lcs_on .lcs_cursor {
    left: 48px;
}
.lcs_switch.lcs_on .lcs_label_on {
    left: 10px;
    opacity: 1;
}
.lcs_switch.lcs_off {
    background: #b2b2b2;
    box-shadow: 0px 0px 2px #a4a4a4 inset;
}
.lcs_switch.lcs_off .lcs_cursor {
    left: 3px;
}
.lcs_switch.lcs_off .lcs_label_off {
    right: 10px;
    opacity: 1;
}
.lcs_switch.lcs_disabled {
    opacity: 0.65;
    cursor: default;
}
.lcs_compact {
    height: 22px;
    width: 47px;
}
.lcs_compact .lcs_label {
    display: none;
}
.lcs_compact .lcs_cursor {
    margin-top: -8px;
    width: 16px;
    height: 16px;
}
.lcs_compact .lcs_switch.lcs_on .lcs_cursor {
    left: 28px;
}