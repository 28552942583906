
.grid-image-wrapper {
  width: 150px;

  .grid-image-caption {
    background: #3d9970 !important;
    color: #FFF;
    a {
      padding: 5px 10px;
      display: block;
      color: #FFF;
    }
    a.feature-photo.active span {
      color: #F8E534;
    }
  }
}
