$listPadding: 8px 0;
$listItemPadding: 0 16px;
$listItemHeight: 48px;
$selectBorderColor: #888888;
$selectItemHoverBackgroundColor: #eaeaea;

@mixin shadow {
  box-shadow: 0 3px 10px #adadad;
}

.ro-select-wrapper {
  display:inline-block;
  position:relative;
  min-width: 150px;
}

.ro-select-text,  {
  border:none;
  border-bottom: 1px solid $selectBorderColor;
  display: block;
  //line-height: @listItemHeight;
  max-height: $listItemHeight;
  padding: 16px 24px 8px 0;
  cursor: pointer;
  &.ro-select-text-empty {
    color: #cccccc;
  }
}

.ro-select-caret {
  position:absolute;
  right:8px;
  top:20px;
  color: $selectBorderColor;
}

.ro-select-list {
  user-select: none;
  position:absolute;
  transition: 0.25s ease;
  top:0;
  left:0;
  width:100%;
  display:none;
  list-style: none;
  margin: 0;
  padding: $listPadding;
  background-color: white;
  @include shadow;
  z-index: 1;
  //border: 1px solid @selectBorderColor;
  > .ro-select-item {
    padding: $listItemPadding;
    line-height: $listItemHeight;
    cursor: pointer;
    &:hover {
      background-color: $selectItemHoverBackgroundColor;
    }
    &.ro-select-item-active {
      background: $selectItemHoverBackgroundColor;
    }
  }
}
