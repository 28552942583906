.config-item {
  padding-bottom: 5px;
  border-bottom: 1px dashed #999;
  margin-bottom: 10px;
}

.config-item:last-child {
  margin: 0;
  border: none;
}
