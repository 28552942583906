#editor{
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#editor-wrapper{
  height: 850px;
  overflow: hidden;
  position: relative;
}

.file-tree ul {
  list-style: none;
  padding: 0;
}
.file-tree .treeview{
  cursor: pointer;
}

.file-tree .treeview-menu {
  display: none;
  list-style: outside none none;
  margin: 0;
  padding: 0 0 0 20px;
}

.file-tree .treeview .icon:before{
  content:"\f114";
}

.file-tree .treeview .icon{
  width: 20px;
}
