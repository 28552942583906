
.admin-tour-title H3 {
  float: left;
  margin-right: 20px;
}

.admin-tour-title span {
  display: block;
  float: left;
  line-height: 5.5em;
  font-size: 12px;
}

.tour-file-dates {
  float: right;
}

.tour-file-dates span {
  display: block;
  font-size: 12px;
}

#tour-date_prices-dates, #tour-date_prices-prices{
  display: none;
}
