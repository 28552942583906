
#hotel-star-category {
  margin-bottom: 15px;
}

#hotel-star-category i {
  font-size: 18px;
  cursor: pointer;
}

#hotel-star-category .form-group {
  margin: 0;
}

#hotel-star-category .help-block {
  margin: 0;
}

#hotel-star-category .starrr .hover {
  color: #F39C12;
}
