@charset "UTF-8";
/** SEARCH BOX */
.ro-select-wrapper {
  display: inline-block;
  position: relative;
  min-width: 150px;
}

.ro-select-text {
  border: none;
  border-bottom: 1px solid #888888;
  display: block;
  max-height: 48px;
  padding: 16px 24px 8px 0;
  cursor: pointer;
}
.ro-select-text.ro-select-text-empty {
  color: #cccccc;
}

.ro-select-caret {
  position: absolute;
  right: 8px;
  top: 20px;
  color: #888888;
}

.ro-select-list {
  user-select: none;
  position: absolute;
  transition: 0.25s ease;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  list-style: none;
  margin: 0;
  padding: 8px 0;
  background-color: white;
  box-shadow: 0 3px 10px #adadad;
  z-index: 1;
}
.ro-select-list > .ro-select-item {
  padding: 0 16px;
  line-height: 48px;
  cursor: pointer;
}
.ro-select-list > .ro-select-item:hover {
  background-color: #eaeaea;
}
.ro-select-list > .ro-select-item.ro-select-item-active {
  background: #eaeaea;
}

/** SWITCH BOX */
.lcs_wrap,
.lcs_wrap * {
  user-select: none;
}

.lcs_wrap {
  display: inline-block;
  direction: ltr;
  height: 28px;
  width: 73px;
  vertical-align: middle;
}

.lcs_wrap input {
  display: none;
}

.lcs_switch {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #ddd;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.lcs_cursor {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: all 0.2s linear;
}

.lcs_label {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #fff;
  font-weight: bold;
  position: absolute;
  width: 33px;
  top: 5px;
  overflow: hidden;
  text-align: center;
  opacity: 0;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out 0.1s;
}

.lcs_label.lcs_label_on {
  left: -70px;
  z-index: 6;
}

.lcs_label.lcs_label_off {
  right: -70px;
  z-index: 5;
}

.lcs_switch.lcs_on {
  background: #75b936;
}

.lcs_switch.lcs_on .lcs_cursor {
  left: 48px;
}

.lcs_switch.lcs_on .lcs_label_on {
  left: 10px;
  opacity: 1;
}

.lcs_switch.lcs_off {
  background: #b2b2b2;
  box-shadow: 0px 0px 2px #a4a4a4 inset;
}

.lcs_switch.lcs_off .lcs_cursor {
  left: 3px;
}

.lcs_switch.lcs_off .lcs_label_off {
  right: 10px;
  opacity: 1;
}

.lcs_switch.lcs_disabled {
  opacity: 0.65;
  cursor: default;
}

.lcs_compact {
  height: 22px;
  width: 47px;
}

.lcs_compact .lcs_label {
  display: none;
}

.lcs_compact .lcs_cursor {
  margin-top: -8px;
  width: 16px;
  height: 16px;
}

.lcs_compact .lcs_switch.lcs_on .lcs_cursor {
  left: 28px;
}

.search-bar {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  clear: both;
  margin-top: 15px;
  width: 80%;
}
.search-bar:after {
  display: block;
  content: "";
  clear: both;
}
.search-bar .input-divider {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.search-bar .input-search-wrapper {
  width: 81%;
}
.search-bar .form-control {
  border: none;
}
.search-bar .ro-select-text {
  padding: 5px 24px 1px 0;
  border: none;
}
.search-bar .ro-select-text.ro-select-text-empty {
  color: #000;
}
.search-bar .ro-select-caret {
  top: 8px;
}

.reset-box {
  width: auto;
  color: #444;
  margin-top: 180px;
}

.file-thumbnail-wrapper {
  background-color: #ccc;
}

#slider_image img.placeholder {
  width: 70px;
  height: 70px;
}

.modal-dialog .modal-body {
  overflow-y: auto;
  position: relative;
}

.modal-dialog .nav-tabs-custom {
  height: 98%;
}

.nav-tabs,
.nav-pills {
  position: relative;
}

div.indent {
  float: left;
  width: 30px;
}

.field-slideritem-image_path {
  overflow: hidden;
}

.file-thumbnail-wrapper,
.file-button-wrapper {
  float: left;
}

.file-thumbnail-wrapper {
  margin-right: 10px;
}

.square-list {
  background-color: #f9f9f9;
  border: 1px solid #fff;
  font-size: 11px;
  height: 115px;
  line-height: 1.4;
  padding: 10px;
  text-align: center;
}

.rounded-image {
  border-color: #888888;
  border-image: none 100% 100% 100% 100%;
  border-radius: 50%;
  border-style: none;
  border-width: 0;
  box-sizing: border-box;
  color: #888888;
  cursor: pointer;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: auto;
  line-height: 21px;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  width: 100%;
}

#config-widget-wrapper {
  overflow: hidden;
}

.map-overlay {
  width: 100%;
  height: 300px;
}

.loader {
  padding: 30px 0;
  margin: 20px;
}

.spinner > div {
  background-color: #21A1B3;
}

.config-item {
  padding-bottom: 5px;
  border-bottom: 1px dashed #999;
  margin-bottom: 10px;
}

.config-item:last-child {
  margin: 0;
  border: none;
}

.grid-image-wrapper {
  width: 150px;
}
.grid-image-wrapper .grid-image-caption {
  background: #3d9970 !important;
  color: #FFF;
}
.grid-image-wrapper .grid-image-caption a {
  padding: 5px 10px;
  display: block;
  color: #FFF;
}
.grid-image-wrapper .grid-image-caption a.feature-photo.active span {
  color: #F8E534;
}

#mediamanager #file-info span {
  display: block;
}

.media-manager-container {
  /*position: absolute;*/
  z-index: 99;
}

.media-manager-container.inline {
  position: relative;
}

.modal-mediamanager {
  width: 1045px;
}

.thumbnails.image_picker_selector {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.thumbnails.image_picker_selector div {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.thumbnails.image_picker_selector div.group_title {
  float: none;
}

.thumbnails.image_picker_selector div.thumbnail {
  padding: 6px;
  border: 1px solid #dddddd;
  float: left;
  position: relative;
  margin: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.thumbnails.image_picker_selector div.thumbnail img {
  -webkit-user-drag: none;
}

.thumbnails.image_picker_selector div.thumbnail span {
  display: block;
  width: 25px;
  position: absolute;
  top: -5px;
  right: -6px;
  border: 2px solid #0088CC;
  background: #FFF;
}

.thumbnails.image_picker_selector div.thumbnail span i {
  margin: 0;
}

.thumbnails.image_picker_selector div.thumbnail .checked {
  background: #FFF;
  font-size: 1.5em;
}

.thumbnails.image_picker_selector div.thumbnail.selected {
  background: #0088cc;
}

.uppy-Root {
  height: 100%;
}
.uppy-Root .uppy-Dashboard {
  height: 100%;
}
.uppy-Root .uppy-Dashboard-poweredBy {
  display: none !important;
}

.uploader-main-wrapper {
  margin: 20px 0 40px;
}

.content-path-link {
  line-height: 0.95rem;
}
.content-path-link a {
  font-size: 14px;
}

.admin-tour-title H3 {
  float: left;
  margin-right: 20px;
}

.admin-tour-title span {
  display: block;
  float: left;
  line-height: 5.5em;
  font-size: 12px;
}

.tour-file-dates {
  float: right;
}

.tour-file-dates span {
  display: block;
  font-size: 12px;
}

#tour-date_prices-dates, #tour-date_prices-prices {
  display: none;
}

#hotel-star-category {
  margin-bottom: 15px;
}

#hotel-star-category i {
  font-size: 18px;
  cursor: pointer;
}

#hotel-star-category .form-group {
  margin: 0;
}

#hotel-star-category .help-block {
  margin: 0;
}

#hotel-star-category .starrr .hover {
  color: #F39C12;
}

.employee-photo {
  height: 150px;
  width: 150px;
}

.employee span {
  display: block;
}

#editor {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#editor-wrapper {
  height: 850px;
  overflow: hidden;
  position: relative;
}

.file-tree ul {
  list-style: none;
  padding: 0;
}

.file-tree .treeview {
  cursor: pointer;
}

.file-tree .treeview-menu {
  display: none;
  list-style: outside none none;
  margin: 0;
  padding: 0 0 0 20px;
}

.file-tree .treeview .icon:before {
  content: "";
}

.file-tree .treeview .icon {
  width: 20px;
}

/*# sourceMappingURL=wmcms-app.css.map */
