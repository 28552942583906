
#mediamanager {}

#mediamanager #file-info span{
  display: block;
}

.media-manager-container{
  //left: 126px;
  /*position: absolute;*/
  //top: 72px;
  z-index: 99;
}

.media-manager-container.inline{
  position: relative;
}

.modal-mediamanager{
  width: 1045px;
}


.thumbnails.image_picker_selector {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.thumbnails.image_picker_selector div {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.thumbnails.image_picker_selector div.group_title {
  float: none;
}

.thumbnails.image_picker_selector div.thumbnail {
  padding: 6px;
  border: 1px solid #dddddd;
  float: left;
  position: relative;
  margin: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.thumbnails.image_picker_selector div.thumbnail img {
  -webkit-user-drag: none;
}

.thumbnails.image_picker_selector div.thumbnail span {
  display: block;
  width: 25px;
  position: absolute;
  top: -5px;
  right: -6px;
  border: 2px solid #0088CC;
  background: #FFF;
}

.thumbnails.image_picker_selector div.thumbnail span i {
  margin: 0;
}

.thumbnails.image_picker_selector div.thumbnail .checked {
  background: #FFF;
  font-size: 1.5em;
}

.thumbnails.image_picker_selector div.thumbnail.selected {
  background: #0088cc;
}
